body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

main {
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
}

.scrollarea {
  overflow-y: auto;
}

div.market-list {
  width: 230px;
}

.search-bar {
  border-radius: 0 0 0 0;
  border: none;
  border-bottom: 1px solid #ced4da !important;
}

.form-control:focus {
  box-shadow: none;
  color: dimgray;
}

.sidebar-divider {
  width: 100%;
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.sidebar-vr {
  flex-shrink: 0;
  width: 1rem;
  height: 100vh;
}

.highlight {
  background-color: cornflowerblue;
}
