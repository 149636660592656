.App {
  text-align: center;
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 100vh;
}

.App-header {
  background-color: lightgray;
  flex-direction: column;
  align-items: center;
  color: black;
}
